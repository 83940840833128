import React, { FC, memo } from 'react'
import cn from 'classnames'
import { includes } from 'lodash/fp'
import { Link } from 'react-router-dom'
import { IDetailsOrder } from '../../../types/orders'
import CrossIcon from '../../../icons/cross.svg'
import OrderStatus from './OrderStatus'
import Tooltip from "../../../components/Tooltip";
import OrderIcon from "../../../orders/OrderIcon";
import {format} from "date-fns";
import ShippingInfo from "../SubmittedOrdersRow/shippingInfo";

interface IOrderHeaderProps {
  order: IDetailsOrder
  onNumberEdit: () => void
  onClose: () => void
  gmFlow?: boolean
}

const OrderHeader: FC<IOrderHeaderProps> = ({
  order,
  onNumberEdit,
  onClose,
  gmFlow,
}) => {
  const submitted = includes(order.status, ['submitted', 'skipped'])

  return (
    <div className="order-modal_head">
      <div className="order-modal_head-number_branch form">
        {order.branch.name}
      </div>

      <div className="order-modal_head-number form">
        <div className="order-modal_title">Order&nbsp;</div>
        {submitted ? (
          <div
            className={cn('order-modal_number', {'-disabled': submitted})}
            onClick={onNumberEdit}
          >
            <span style={{color: '#E4922F'}}>{order.navOrderId}</span>
            {order.navOrderId && order.purchaseOrderNumber && <>&nbsp;/&nbsp;</>}
            {order.purchaseOrderNumber}
          </div>
        ) : (
          <>
            -&nbsp;
            <div
              className={cn('order-modal_number', {'-disabled': submitted})}
              onClick={onNumberEdit}
            >
              {order.purchaseOrderNumber || 'Add PO Number'}
            </div>
          </>
        )}
        <Link to="#" className="order-modal_back" onClick={onClose}>
          <CrossIcon width={15} height={16}/>
        </Link>
      </div>

      <div className="order-modal_status">
        <div className="order-modal_status-container form">
          <div className="order-modal_status-steps" style={{textTransform: 'none', fontSize: '14px', fontWeight: 400}}>
            <ShippingInfo order={order} title="Estimated shipment on" fullDate={true}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(OrderHeader)
