import React, { FC, useCallback, useEffect, useState, useRef } from 'react'
import {sortBy, reject, sumBy} from 'lodash/fp'
import { RouteComponentProps } from 'react-router'
import { format } from 'date-fns'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import useThunkDispatch from '../../../common/useThunkDispatch'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import Splash from '../../../components/Splash'
import Search from '../../../components/Search'
import {fetchFlowAccessForShoppingCart, ICartItem, IDetailsCart} from '../../../types/orders'
import {cartTotal, plannedCartTotal} from '../../../common/orders'
import withAccountAndBranch from '../../../common/withAccountAndBranch'
import { searchProducts } from '../../../accounts/accountsActions'
import { IAccount, IBranch, IUser } from '../../../types/user'
import { IProduct } from '../../../types/products'
import {
  requestOrderSubmitFromCart,
  requestUpdateCartItem,
  requestCreateCartItem,
  requestOrderRequestFromCart, requestCartBranchUpdate,
} from '../ordersActions'
import { useOrderPONumberModal } from './OrderPONumber'
import { useOrderRequestModal } from './OrderRequest'
import { find } from 'lodash'
import ShoppingCartHeader from "./ShoppingCartHeader";
import ShoppingCartRow from "../ShoppingCartRow";
import ShoppingCartProductRow from "../ShoppingCartProductRow";
import {requestSetManagerBranch} from "../../managerActions";

interface IOrderRouteParams
  extends RouteComponentProps<{
    accountId?: string
    branchId?: string
    returnUrl?: string
  }> {
  user: IUser
  account?: IAccount
  branch?: IBranch
}

const ShoppingCart: FC<IOrderRouteParams> = ({
                                               user,
                                               account: currentAccount,
                                               branch,
                                               match,
                                               location,
                                               history,
                                             }) => {
  const dispatch = useThunkDispatch()
  const [term, setTerm] = useState<string>('')
  const [debouncedTerm, setDebouncedTerm] = useState<string>('')
  const activeRequestId = useRef(0)
  const [foundProducts, setFoundProducts] = useState<IProduct[] | null>(null)
  const [searching, setSearching] = useState<boolean>(false)
  const [requesting, setRequesting] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const listRef = useRef<FixedSizeList>(null)
  const account = currentAccount || user.account
  const nothingFound = foundProducts && !foundProducts.length && !searching

  const loading = searching || requesting || submitting

  const [cartBranch, setCartBranch] = useState(branch);
  const [poNumber, setPoNumber] = useState<string>("")
  const flowAccess = cartBranch && account ? fetchFlowAccessForShoppingCart(account, user, cartBranch) : {}
  const isEditable = flowAccess.canEdit
  let cartItems = (cartBranch && reject({ amountRequested: 0 }, cartBranch.cartItems)) || []
  cartItems = (cartBranch && reject({ suggestion: true }, cartBranch.cartItems)) || []

  // sort cart items by product name
  cartItems = cartItems.sort((a, b) => a.product.name.localeCompare(b.product.name));
  const isEmpty = sumBy('amountRequested', cartItems) === 0

  useEffect(() => {
    setCartBranch(branch)
  }, [branch]);

  useEffect(() => {
    if (!cartBranch) return;

    const fetchCartBranchAndPopulate = async () => {
      const params = new URLSearchParams(location.search);
      const populate = params.get("populate");

      if (populate === "true" && isEmpty
        && branch && branch.suggestedCartItems && branch.suggestedCartItems.length > 0) {
        try {
          handleAddSuggestedCartItemsToCart();
          params.delete("populate");
          history.replace({
            pathname: location.pathname,
            search: params.toString(),
          });
        } catch (error) {
          console.error("Error populating cart:", error);
        }
      }
    };
    fetchCartBranchAndPopulate();
  }, [cartBranch]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(term)
    }, 300)

    return () => clearTimeout(handler)
  }, [term])

  useEffect(() => {
    if (!debouncedTerm) {
      setFoundProducts(null)
      return
    }

    const performSearch = async () => {
      const requestId = ++activeRequestId.current
      setSearching(true)

      const results = await dispatch(
        searchProducts(
          account ? account.id : user.account!.id,
          cartBranch!.id,
          debouncedTerm,
          'orderable'
        )
      )
      if (requestId !== activeRequestId.current) return;

      setFoundProducts(sortBy('name', results))

      if (listRef.current) listRef.current.scrollTo(0)
      setSearching(false)

    }

    performSearch().catch((error) => {
      console.error("performSearch error:", error)
    })
  }, [debouncedTerm])

  // on back
  const handleBack = useCallback(() => {
    history.push('/orders');
  }, [account, branch]);

  // on update
  const handleUpdate = useCallback(
    (attrs: Partial<IDetailsCart>) => {
      const perform = async () => {
        setSubmitting(true)
        const { result, errors } = await dispatch(requestCartBranchUpdate(cartBranch!.id, attrs))
        setSubmitting(false)

        if (result) {
          setCartBranch({...cartBranch, ...result})
        } else if (errors) {
          alert(errors)
        }
      }

      perform()
    },
    [cartBranch]
  )

  // on cart item create
  const handleCartItemCreate = useCallback(
    (product: IProduct) => {
      const perform = async () => {
        const existingCartItem = cartItems!.find(
          oi => oi.product.id === product.id
        )

        if (existingCartItem) {
          handleCartItemUpdate({ ...existingCartItem, amountRequested: 1 })
          return
        }

        setSubmitting(true)
        const { result, errors } = await dispatch(
          requestCreateCartItem(cartBranch!, { amountRequested: 1, product })
        )
        setSubmitting(false)

        if (result) {
          setCartBranch(result)
        } else if (errors) {
          alert(errors)
        }
      }

      perform()
    },
    [cartBranch]
  )

  // on cart item update
  const handleCartItemUpdate = useCallback(
    (item: ICartItem) => {
      const perform = async () => {
        setSubmitting(true)
        const { result, errors } = await dispatch(
          requestUpdateCartItem(cartBranch!, item)
        )
        setSubmitting(false)

        if (result) {
          setCartBranch(result)
        } else if (errors) {
          alert(errors)
        }
      }

      perform()
    },
    [cartBranch]
  )

  // on cart item remove
  const handleCartItemDelete = useCallback(
    (item: ICartItem) => {
      const perform = async () => {
        setSubmitting(true)
        const { result, errors } = await dispatch(
          requestUpdateCartItem(cartBranch!, { ...item, amountRequested: 0 })
        )
        setSubmitting(false)

        if (result) {
          setCartBranch(result)
        } else if (errors) {
          alert(errors)
        }
      }

      perform()
    },
    [cartBranch]
  )

  // on cart item request
  const handleRequest = useCallback(() => {
    const perform = async () => {
      setRequesting(true)
      const { result, errors } = await dispatch(requestOrderRequestFromCart(cartBranch!.id))
      setRequesting(false)

      if (result) {
        setCartBranch(result.branch)
        handleBack()
      } else if (errors) {
        alert(errors)
      }
    }

    perform()
  }, [cartBranch, handleBack])

  // on order submit
  const handleSubmit = useCallback(() => {
    const perform = async () => {
      setSubmitting(true)
      const { result, errors } = await dispatch(requestOrderSubmitFromCart(cartBranch!.id))
      setSubmitting(false)

      if (result) {
        setCartBranch(result.branch)
        handleBack()
      } else if (errors) {
        alert(errors)
      }
    }

    perform()
  }, [cartBranch, handleBack])

  const handleAddSuggestedCartItemsToCart = useCallback(() => {
    const perform = async () => {
      setSubmitting(true)
      const { result, errors } = await dispatch(requestCartBranchUpdate(
        cartBranch!.id, { moveSuggestedCartItemsToCart: true })
      )
      setSubmitting(false)

      if (result) {
        await dispatch(requestSetManagerBranch(result))
        setCartBranch(result)
      } else if (errors) {
        alert(errors)
      }
    }

    perform()
  }, [cartBranch, handleBack])

  // on clear cart
  const handleClearCart = useCallback(() => {
    const perform = async () => {
      setSubmitting(true)
      const { result, errors } = await dispatch(requestCartBranchUpdate(cartBranch!.id, { clearCartItems: true }))
      setSubmitting(false)

      if (result) {
        await dispatch(requestSetManagerBranch(result))
        setCartBranch(result)
      } else if (errors) {
        alert(errors)
      }
    }

    perform()
  }, [cartBranch])

  // request
  const [
    { open: openRequestModal, close: closeRequestModal },
    requestModal,
  ] = useOrderRequestModal({
    onRequest: () => {
      handleRequest()
      closeRequestModal()
    },
  })

  // po number
  const [
    { open: openPONumberModal, close: closePONumberModal },
    poNumberModal,
  ] = useOrderPONumberModal({
    poNumber,
    onSave: (poNumber: string) => {
      handleUpdate({ purchaseOrderNumber: poNumber })
      setPoNumber(poNumber)
      closePONumberModal()
    },
  })

  if (!branch || !cartBranch) return <Splash isLoading />

  const multiCatalogEnabled = (account?.erpCatalogs || []).length > 1
  const showLowStock =
    isEditable &&
    !!cartItems!.length &&
    !!find(
      cartItems,
      oi =>
        oi.product.stockInventory !== undefined &&
        oi.amountRequested &&
        oi.amountRequested > oi.product.stockInventory
    )

  return (
    <Modal closeable={false} containerClassName="">
      <div className="order-modal">
        <ShoppingCartHeader
          branch={cartBranch}
          onNumberEdit={openPONumberModal}
          onClose={handleBack}
        />
        <div className="form">
          {isEmpty && branch && branch.suggestedCartItems && branch.suggestedCartItems.length > 0 && (
            <div className="order-modal_forecast">
              <div className="order-modal_forecast_info">
                <div className="order-modal_forecast_info_text">
                  <div className="order-modal_forecast_info_text_title">
                    {branch.suggestedCartItems.length} items ({plannedCartTotal(branch)})
                  </div>
                  <div className="order-modal_forecast_info_text_subtitle">planned for order according forecast</div>
                </div>

                <Button
                  type="submit"
                  color="primary"
                  disabled={submitting}
                  className="order-modal_forecast_button"
                  onClick={handleAddSuggestedCartItemsToCart}
                >
                  Add to order
                </Button>
              </div>

              <div className="order-modal_forecast_separated"></div>

              <div className="order-modal_forecast_info_note">
                <div className="order-modal_forecast_info_note_text">
                  * according your replenishment plan Foresignt is able to forecast your next order
                </div>
              </div>
            </div>
          )}

          <div className="order-modal_search">
            <div className="order-modal_field">
              <Search
                searching={searching}
                placeholder="Search item name or ID to add"
                value={term}
                onChange={e =>
                  setTerm(e.target.value ? e.target.value.trim() : '')
                }
              />
            </div>
            <div className="order-modal_total">
              Total
              <br/>
              <strong>{cartTotal(cartBranch)}</strong>
            </div>
          </div>
          {showLowStock && (
            <div className="order-modal_alert">
              Some items could be delayed due to a lack of availability -
              Forshaw CSR will be in touch to discuss
            </div>
          )}
          <div className="order-modal_list">
            {nothingFound && (
              <div className="table">
                <div className="table_head-tr">
                  <div className="table_head-tr table_td table_title">
                    No products found
                  </div>
                </div>
              </div>
            )}
            <AutoSizer
              className="table"
              style={{ height: 'calc(100vh - 375px)' }}
            >
              {size => (
                <FixedSizeList
                  ref={listRef}
                  className="wonder-scroll"
                  {...size}
                  itemData={{
                    editable: isEditable,
                    products: foundProducts,
                    onAdd: handleCartItemCreate,
                    onUpdate: handleCartItemUpdate,
                    onDelete: handleCartItemDelete,
                    cartItems,
                    multiCatalogEnabled,
                    loading,
                  }}
                  itemCount={
                    foundProducts ? foundProducts.length : cartItems!.length
                  }
                  itemSize={100 + 2}
                  itemKey={index =>
                    foundProducts
                      ? foundProducts[index].id
                      : cartItems![index].id
                  }
                >
                  {foundProducts ? ShoppingCartProductRow : ShoppingCartRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
        <div className="form_controls">
          <div className="order-modal_footer form_controls-container">
            {
              <Button
                onClick={handleClearCart}
                color="danger"
                className="form_control -size -left"
              >
                Clear order
              </Button>
            }

            <div className="order-modal_footer_update_info">
              {(() => {
                if (!cartBranch?.cartItems?.length) {
                  return '';
                }

                const lastCartItem = cartBranch.cartItems.at(-1);

                const updatedAt = lastCartItem?.updatedAt
                  ? format(new Date(lastCartItem.updatedAt), 'MMM DD, YYYY')
                  : 'n/a';

                const lastCartItemUpdatedBy = lastCartItem?.updatedBy;
                const name =
                  lastCartItemUpdatedBy?.name === 'System user'
                    ? 'ForeSight'
                    : lastCartItemUpdatedBy?.name || 'n/a';

                return `Updated ${updatedAt} by ${name}`;
              })()}
            </div>

            {flowAccess.canRequest && (
              <Button
                type="submit"
                color="success"
                disabled={requesting}
                className="form_control"
                onClick={openRequestModal}
              >
                Request approval
              </Button>
            )}
            {flowAccess.canSubmit && (
              <Button
                type="submit"
                color="success"
                disabled={submitting}
                className="form_control"
                onClick={handleSubmit}
              >
                Place order
              </Button>
            )}
          </div>
        </div>
      </div>
      {poNumberModal}
      {requestModal}
    </Modal>
  )
}

export default withAccountAndBranch(ShoppingCart)
