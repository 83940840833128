import React, { FC, memo, useState } from 'react'
import useDebounce from 'react-use/lib/useDebounce'
import { startOfMonth, addMonths, subMonths, format } from 'date-fns'

import Search from '../../components/Search'
import * as storage from '../../common/storage'

import ArrowLeftIcon from '../../icons/arrow_left.svg'
import ArrowRightIcon from '../../icons/arrow_right.svg'
import { IOrdersFilter } from './ordersActions'
import {IAccount} from "../../types/user";
import {getShouldRenderNewReplenishmentPlan} from "../../common/getShouldRenderNewReplenishmentPlan";

interface IOrdersFilterProps {
  loading?: boolean
  branchSelected?: boolean
  onChange: (term: string, filter: IOrdersFilter) => void
  account?: IAccount
}

export const FILTER_STORAGE_KEY = 'manager.orders.filter'

const OrdersFilterV2: FC<IOrdersFilterProps> = ({
  loading,
  onChange,
  account,
}) => {
  const [term, setTerm] = useState<string>('')
  const [filter, setFilter] = useState<IOrdersFilter>(
    JSON.parse(storage.get(FILTER_STORAGE_KEY, '{"scope":"manageable"}'))
  )
  const period = filter.period || format(startOfMonth(new Date()), 'YYYY-MM-DD')
  const shouldRenderNewReplenishmentPlan = getShouldRenderNewReplenishmentPlan(
    account?.replenishmentPlanVersion
  )

  // search by term
  useDebounce(
    () => {
      const previousTerm = storage.get('previousTerm', '');
      if (term !== previousTerm) {
        storage.set('previousTerm', term);
        onChange(term, filter);
      }
    },
    300,
    [term]
  )

  // filter changed
  const updateFilter = (newFilter: IOrdersFilter) => {
    setFilter(newFilter)
    onChange(term, newFilter)
    storage.set(FILTER_STORAGE_KEY, JSON.stringify(newFilter))
  }

  const nextMonth = () => {
    updateFilter({
      ...filter,
      period: format(addMonths(period, 1), 'YYYY-MM-DD'),
    })
  }

  const prevMonth = () => {
    updateFilter({
      ...filter,
      period: format(subMonths(period, 1), 'YYYY-MM-DD'),
    })
  }

  return (
    <div className="filter">
      <div className="filter_col -large">
        <Search
          searching={loading}
          placeholder="Search by PO Number or Requested by"
          value={term}
          onChange={e => setTerm(e.target.value)}
        />
      </div>
      <div className="filter_col" style={{ marginRight: 30 }}>
        <div className="pager">
          <button
            disabled={loading}
            onClick={prevMonth}
            className="pager_button clear-button link"
          >
            <ArrowLeftIcon />
          </button>
          <div className="pager_title">{format(period, 'MMM, YYYY')}</div>
          <button
            disabled={loading}
            onClick={nextMonth}
            className="pager_button clear-button link"
          >
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default memo(OrdersFilterV2)
