import { capitalize } from 'lodash/fp'
import { IOrder } from '../types/orders'
import { money } from './display'
import name from './name'
import {IBranch} from "../types/user";

export const orderTotal = (order: IOrder): string =>
  money(
    order.cost ||
      (order.orderItems || [])
        .reduce(
          (acct, i) => acct + i.amountRequested * +(i.product.price || 0),
          0
        )
        .toFixed(2)
  )

export const plannedCartTotal = (branch: IBranch): string =>
  money(
    ((branch!.cartItems!.length > 0 && branch!.cartItems)
      || (branch!.suggestedCartItems!.length > 0 && branch.suggestedCartItems) || [])
      .reduce(
        (acct, i) => acct + i.amountRequested * +(i.product.price || 0),
        0
      )
      .toFixed(2)
  )

export const cartTotal = (branch: IBranch): string =>
  money(
    (branch.cartItems || [])
      .reduce(
        (acct, i) => acct + i.amountRequested * +(i.product.price || 0),
        0
      )
      .toFixed(2)
  )

export const cartItemsAmount = (branch: IBranch): string =>
  branch!.cartItems!.length > 0 ? branch!.cartItems!.length.toString() : branch!.suggestedCartItems!.length.toString()

export const orderStatus = (order: IOrder): string => {
  if (order.status === 'requested') return 'Pending Approval'

  return capitalize(order.status)
}

export const orderOriginatedBy = (order: IOrder, gmFlow?: boolean): string => {
  const requestedName = name(order.requestedBy)
  const submittedName = name(order.submittedBy)
  const approvedName = name(order.approvedBy)
  const orderType = order.kind

  if (gmFlow) {
    return requestedName || '-'
  }

  const result =
    (orderType === 'fill_in' ? submittedName : approvedName) || undefined

  return result || name(order.updatedBy) || '-'
}
