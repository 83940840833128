import {
  IBranchForecast,
  IBranchShipment, ICartItem,
  IOrder,
  IStatusableOrder,
} from './orders'
import { IProduct, IBranchesProduct, IProductKind } from './products'

export const STORAGE_USER_TOKEN_KEY = 'userToken'
export const STORAGE_USER_KEY = 'user'

export type UserRole = 'internal' | 'manager' | 'technician' | 'planner' | 'checkout_manager' | 'general_manager' | 'general_and_checkout_manager' | ''

export type ReplenishmentOrdinals =
  | 'first'
  | 'fourth'
  | 'last'
  | 'second'
  | 'third'

export type ReplenishmentWeekDays =
  | 'friday'
  | 'monday'
  | 'thursday'
  | 'tuesday'
  | 'wednesday'

export interface IUser {
  id: number
  name: string
  avatar?: string
  role: UserRole
  email?: string
  lastSignInAt?: string
  branches?: IBranch[]
  divisions?: IDivision[]
  teams?: ITeam[]
  status?: string
  resetPasswordSentAt?: string
  isRemovable?: boolean
  isMovable?: boolean
  account?: IAccount
  password?: string
  generalManager?: boolean
  checkoutManager?: boolean
  updatedAt?: string
}

export interface IBranch {
  id: number
  name: string
  navId?: string
  plannedProductsCount?: number
  shipmentApproveFlow?: boolean
  address?: string
  address2?: string
  zip?: string
  city?: string
  state?: string
  products?: IProduct[]
  actualOrders?: IStatusableOrder[]
  plannedYears?: number[]
  forecasts?: IBranchForecast[]
  shipments?: IBranchShipment[]
  replenishmentOrdinal?: ReplenishmentOrdinals
  replenishmentWeekDay?: ReplenishmentWeekDays
  showTechPrice?: boolean
  invoiceMappingRow?: string
  sanaLoginManager?: IUser
  shipToCode?: string
  cartItems?: ICartItem[]
  suggestedCartItems?: ICartItem[]
  purchaseOrderNumber?: string
}
export type IPlannedBranch = IBranch & {
  plannedYears: number[]
  forecasts: IBranchForecast[]
  shipments: IBranchShipment[]
  products?: IProduct[]
}

export interface IBILink {
  id: number
  title: string
  url: string
}

export interface IAccount {
  id: number
  name: string
  logo?: string
  branches: IBranch[]
  products?: IProduct[]
  productsCheckoutable?: IProduct[]
  productsOrderable?: IProduct[]
  accountProducts?: IProduct[]
  catalog?: IProduct[]
  users?: IUser[]
  managers?: IUser[]
  divisions?: IDivision[]
  branchesProduct?: IBranchesProduct[]
  navAccountId?: string
  navSalesCode?: string
  lastSync?: IAccountSync
  biLinks: IBILink[]
  orderApproveFlow: boolean
  live?: boolean
  replenishmentPlanVersion?: string
  ordersEnabled: boolean
  erpCatalogs: string[]
  erpOrders?: string
}

export interface IAccountSync {
  id: number
  status: 'scheduled' | 'running' | 'finished' | 'failed'
  finishedAt: string
  syncErrors: {
    [key: string]: string
  }
  startedAt: string
  createdAt: string
}

export interface IDivision {
  id: number
  name: string
  managers?: IUser[]
  technicians?: IUser[]
  products?: IProduct[]
  branchId: number
}

export interface ITeam {
  id: number
  name: string
  managers?: IUser[]
  technicians?: IUser[]
  branchId: number
}

export interface ICheckout {
  name: number
  asap: boolean
  cost: number
  createdAt: string
  updatedAt: string
  id: number
  totalItems: number
  totalUnits: number
}
