export const MANAGER_BRANCHES_QUERY = `
query {
  manager {
    branches {
      id
      name
      purchaseOrderNumber
      cartItems {
        id
        unit
        amountRequested
        branchId
        updatedAt
        updatedBy { name }
        product {
          id
          name
          code
          photo
          salesUnit
          unit
          price
          stockState
          stockInventory
          erpCatalogName
         }
      }
      suggestedCartItems {
        id
        unit
        amountRequested
        branchId
        updatedAt
        updatedBy { name }
        product {
          id
          name
          code
          photo
          salesUnit
          unit
          price
          stockState
          stockInventory
          erpCatalogName
         }
      }
    }
  }
}
`

export const ACCOUNT_BRANCHES_QUERY = `
query {
  manager {
    account {
      branches {
        id
        name
      }
    }
  }
}
`

export const ACCOUNT_LINKS_QUERY = `
query {
  manager {
    account {
      biLinks {
        id
        title
        url
      }
    }
  }
}
`

const DIVISION_FRAGMENT = `
fragment division on Division {
  id
  name
  technicians {
    id
    name
    email
  }
  products: checkoutableProducts {
    id
    code
    name
    photo
    salesUnit
    unit
    quantity
    price
    kind
  }
}
`

const TEAM_FRAGMENT = `
fragment team on Team {
  id
  name
  technicians {
    id
    name
    email
  }
}
`

export const DIVISIONS_MANAGER_QUERY = `
${DIVISION_FRAGMENT}
${TEAM_FRAGMENT}

query BranchDivisions($branchId: Int!) {
  manager {
    account {
      id
    }

    currentBranch: branches(id: $branchId) {
      divisions {
        ...division
      }
      teams {
        ...team
      }
    }
  }
}
`

export const DIVISIONS_INTERNAL_QUERY = `
${DIVISION_FRAGMENT}
${TEAM_FRAGMENT}

query BranchDivisions($branchId: Int!, $accountId: Int!) {
  internal {
    accounts(id: $accountId) {
      nodes {
        id

        currentBranch: branches(id: $branchId) {
          divisions {
            ...division
          }
          teams {
            ...team
          }
        }
      }
    }
  }
}
`

export const UPDATE_DIVISION_QUERY = `
${DIVISION_FRAGMENT}
mutation UpdateDivision($id: Int!, $attributes: DivisionBaseAttributes!) {
  divisionUpdate(id: $id, attributes: $attributes) {
    errors
    result {
      ...division
    }
  }
}
`

export const CREATE_DIVISION_QUERY = `
${DIVISION_FRAGMENT}
mutation CreateDivision($attributes: DivisionCreateAttributes!) {
  divisionCreate(attributes: $attributes) {
    errors
    result {
      ...division
    }
  }
}
`

export const REMOVE_DIVISION_QUERY = `
mutation RemoveDivision($id: Int!) {
  divisionRemove(id: $id) {
    errors
  }
}
`

const INBOUND_INVENTROY_FRAGMENT = `
fragment inboundInventory on InboundInventory {
  id
  navId
  navOriginalOrderId
  recordType
  invoiceDate
  itemsCount
  approvedItemsCount
  totalAmount
  totalPrice
  approvedAmount
}
`

export const INBOUND_INVENTORIES_MANAGER_QUERY = `
${INBOUND_INVENTROY_FRAGMENT}

query BranchInboundInventory($branchId: Int!) {
  manager {
    account {
      id
    }

    currentBranch: branches(id: $branchId) {
      inboundInventories {
        ...inboundInventory
      }
    }
  }
}
`

export const INBOUND_INVENTORIES_INTERNAL_QUERY = `
${INBOUND_INVENTROY_FRAGMENT}

query BranchInboundInventory($branchId: Int!, $accountId: Int!) {
  internal {
    accounts(id: $accountId) {
      nodes {
        id

        currentBranch: branches(id: $branchId) {
          inboundInventories {
            ...inboundInventory
          }
        }
      }
    }
  }
}
`

export const FIND_INBOUND_INVENTORY_QUERY = `
${INBOUND_INVENTROY_FRAGMENT}

query FindInboundInventory($inboundInventoryId: Int!) {
  internal {
    inboundInventoryFind(id: $inboundInventoryId) {
      ...inboundInventory
      shipments {
        id
        product {
          id
          name
          code
          photo
          salesUnit
          unit
          price
        }
        amount
        approvedAmount
        approvedAt
        hasProduct
        currentInventory
      }
      transfers {
        id
        sourceBranchName
        sourceAuthorName
        product {
          id
          name
          code
          photo
          salesUnit
          unit
          price
        }
        destinationAmount
        approvedAmount
        approvedAt
        hasProduct
        currentInventory
      }
    }
  }
}
`

export const MANAGER_FIND_INBOUND_INVENTORY_QUERY = `
${INBOUND_INVENTROY_FRAGMENT}

query FindInboundInventory($inboundInventoryId: Int!) {
  manager {
    inboundInventoryFind(id: $inboundInventoryId) {
      ...inboundInventory
      shipments {
        id
        product {
          id
          name
          code
          photo
          salesUnit
          unit
          price
        }
        amount
        approvedAmount
        approvedAt
        hasProduct
        currentInventory
      }
      transfers {
        id
        sourceBranchName
        sourceAuthorName
        product {
          id
          name
          code
          photo
          salesUnit
          unit
          price
        }
        destinationAmount
        approvedAmount
        approvedAt
        hasProduct
        currentInventory
      }
    }
  }
}
`

export const APPROVE_INBOUND_INVENTORY_QUERY = `
${INBOUND_INVENTROY_FRAGMENT}

mutation ApproveInboundInventory($id: Int!, $inboundData: [InboundApproveAttributes!]!) {
    inboundInventoryApprove(id: $id, inboundData: $inboundData) {
    errors,
    result {
      ...inboundInventory
      shipments {
        id
        product {
          id
          name
          code
          photo
          salesUnit
          unit
          price
        }
        amount
        approvedAmount
        approvedAt
        hasProduct
        currentInventory
      }
      transfers {
        id
        sourceBranchName
        sourceAuthorName
        product {
          id
          name
          code
          photo
          salesUnit
          unit
          price
        }
        destinationAmount
        approvedAmount
        approvedAt
        hasProduct
        currentInventory
      }
    }
  }
}`

export const TEAMS_MANAGER_QUERY = `
${TEAM_FRAGMENT}

query BranchTeams($branchId: Int!) {
  manager {
    account {
      id
    }

    currentBranch: branches(id: $branchId) {
      teams {
        ...team
      }
    }
  }
}
`

export const TEAMS_INTERNAL_QUERY = `
${TEAM_FRAGMENT}

query BranchTeams($branchId: Int!, $accountId: Int!) {
  internal {
    accounts(id: $accountId) {
      nodes {
        id

        currentBranch: branches(id: $branchId) {
          teams {
            ...team
          }
        }
      }
    }
  }
}
`

export const UPDATE_TEAM_QUERY = `
${TEAM_FRAGMENT}
mutation UpdateTeam($id: Int!, $attributes: TeamBaseAttributes!) {
  teamUpdate(id: $id, attributes: $attributes) {
    errors
    result {
      ...team
    }
  }
}
`

export const CREATE_TEAM_QUERY = `
${TEAM_FRAGMENT}
mutation CreateTeam($attributes: TeamCreateAttributes!) {
  teamCreate(attributes: $attributes) {
    errors
    result {
      ...team
    }
  }
}
`

export const REMOVE_TEAM_QUERY = `
mutation RemoveTeam($id: Int!) {
  teamRemove(id: $id) {
    errors
  }
}
`
