import { ComponentType, default as React } from 'react'
import { ListChildComponentProps } from 'react-window'
import { format } from 'date-fns'
import cn from 'classnames'

import name from '../../../common/name'
import Tooltip from '../../../components/Tooltip'
import { orderTotal } from '../../../common/orders'
import { IDetailsOrder } from '../../../types/orders'
import OrderStatus from "../../../orders/OrderStatus";
import ShippingInfo from "../SubmittedOrdersRow/shippingInfo";

type IOrdersRow = ComponentType<ListChildComponentProps>

const OrdersRowV2: IOrdersRow = ({ data, index, style }) => {
  const order = data.orders[index] as IDetailsOrder
  const { allBranches, multiCatalogEnabled } = data

  const hasShipments = !!order.orderTrackingStatuses.length

  return (
    <>
      <div style={style}>
        <div
          className="table_tr -fixed-height -link"
          onClick={() => data.onClick(order)}
        >
          <div className="table_td -g20">
            {order.submittedAt ? format(order.submittedAt, 'MMM D, YYYY') : '-'}
          </div>
          {allBranches && (
            <div className="table_td -g25">
              {order.branch ? order.branch.name : '-'}
            </div>
          )}
          <div className="table_td -g25">
            <Tooltip id={`tooltip-updated-by-${order.id}`}>
              <div
                className="input_list-tooltip"
                data-tip={
                  order.updatedAt
                    ? format(order.updatedAt, 'MMM D, YYYY HH:MM')
                    : '-'
                }
                data-for={`tooltip-updated-by-${order.id}`}
                data-effect="solid"
                data-place="bottom"
              >
                {name(order.updatedBy)}
              </div>
            </Tooltip>
          </div>
          {order.status === 'submitted' ? (
            <div className="table_td -g20">
              <div
                className={cn('table_text -lines-2')}
                style={{color: hasShipments ? '#3ABF77' : '#0061D2'}}
              >
                {hasShipments ? 'Shipped' : 'Submitted'}
              </div>
            </div>
          ) : (
            <div className="table_td -g20">
              <div
                className={cn('table_text status -lines-2', {
                  '-success': order.status === 'approved',
                  '-danger': order.status === 'rejected',
                })}
              >
                {(order.status !== 'rejected' || !order.rejectReason) && (
                  <OrderStatus order={order} short/>
                )}
                {order.status === 'rejected' && order.rejectReason && (
                  <Tooltip id={`tooltip-updated-by-${order.id}`}>
                    <div
                      className="input_list-tooltip"
                      data-tip={order.rejectReason}
                      data-for={`tooltip-updated-by-${order.id}`}
                      data-effect="solid"
                      data-place="bottom"
                    >
                      {<OrderStatus order={order} short/>}
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          )}
          {multiCatalogEnabled && <div className="table_td -g20">{order.erpCatalogName || '-'}</div>}
          <div className="table_td -g20">{order.navOrderId || '-'}</div>
          <div className="table_td -g20">{order.purchaseOrderNumber || '-'}</div>
          <div className="table_td -g20">{orderTotal(order)}</div>
          <div className="table_td -g25" onClick={e => e.stopPropagation()}>
            {order.status === 'submitted' ? <ShippingInfo order={order} /> : '-'}
          </div>
        </div>
      </div>
    </>
  )
}

export default OrdersRowV2
